import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { FiMail } from 'react-icons/fi';
import { VscLoading } from 'react-icons/vsc';
import { ThemeProvider } from 'styled-components';

import { RiWhatsappLine } from "react-icons/ri";
import { MdContactMail } from "react-icons/md";

import Config from './config';

import Api from './services/Api';
import Socket from './services/Socket';

import getImages from './utils/getImages';
import defaultBg from './assets/default_bg.png';

import Logo from './components/Logo';
import Infos from './components/Infos';
import Links from './components/Links';
import Mapa from './components/Mapa';
import Socials from './components/Socials';
import WrapChat from './components/WrapChat';
import WrapMapa from './components/WrapMapa/index';
import FormModal from './components/FormModal';
import { SelectOptions } from './components/Form/Select';
import setMetaTags from './utils/setMetatags';
import { LinksPage } from './components/Links';
import { SocialNetworks } from './components/Socials';
import GlobalStyles from './styles/global';
import theme from './styles/theme';
import { Container, Filter, Footer, SocialsAndForm, CtaForm, Loading } from './styles';

interface Page {
  id: number,
  title: string,
  telefone: string,
  endereco: string,
  buttonName: string,
  mapa: string,
  whatsapp: string,
  description: string,
  metaTitle: string,
  metaDescription: string,
  metaKeywords: string,
  colorPrimary: string,
  colorSecondary: string,
  colorTertiary: string,
  colorQuaternary: string,
  colorForm: string,
  colorFontForm: string,
  colorbgform: string,
  colorbtnform: string,
  colorBackground: string,
  colorBtnfont: string,
  colorNetworks: string,
  colorDescription: string,
  image_logo: { path: string },
  image_background: { path: string },
  flagForm: boolean,
  flagChat: boolean,
  flagSocials: boolean,
  flagWhatsapp: boolean,
  flagHistorico: boolean,
  flagMapa: boolean,
  flagEndereco: boolean,
  flagProfileBorder: boolean,
  flagBackgroundFilter: boolean,
}

interface FormSubjects {
  id: number,
  id_setor: number,
  name: string
}

interface Content {
  page: Page,
  form_subjects: SelectOptions[]
  links: LinksPage[],
  social_networks: SocialNetworks[]
  setores: any
}

interface Colors {
  primary: string,
  secondary: string,
  tertiary: string,
  quaternary: string,
  bgform: string,
  fontform: string,
  btnform: string,
  form: string,
  btnfont: string,
  networks: string,
  description: string,
  border: string,
  background: string
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState({} as Content);
  const [showFormModal, setShowFormModal] = useState(false);
  const [loadedColors, setLoadedColors] = useState<Colors>({} as Colors);
  const [telefone, setTelefone] = useState('');
  const [endereco, setEndereco] = useState('');
  const [buttonName, setButtonName] = useState('');

  const [footerContent, setFooterContent] = useState('');
  const [hasFooter, setHasFooter] = useState(false);
  const [heightFooter, setHeightFooter] = useState(0);
  const [hasMap, setHasMap] = useState(false);
  const [linksCount, setLinksCount] = useState(0);
  const [showMapa, setShowMapa] = useState(false);

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);
  const [typeImage, seTtypeImage] = useState('');
  const [hasBg, setHasBg] = useState(false);

  const handleValueFromChild = (val: boolean) => {
    setShowMapa(val);
  }

  const loadContents = async () => {
    setIsLoading(true);

    let endpoint = "";

    if (window.location.href.includes('merx.app.br') || window.location.href.includes('localhost')) {
      if (window.location.pathname.lastIndexOf("/")) {
        endpoint = window.location.pathname.substring(
          window.location.pathname.indexOf("/") + 1,
          window.location.pathname.lastIndexOf("/")
        );
      } else {
        endpoint = window.location.pathname.replace(new RegExp("/", "g"), '');
      }
    } else {
      endpoint = window.location.hostname;
    }

    let response = (await Api.get(`/page-endpoint/${endpoint}`)).data;
    //let response = (await Api.get(`/page/${Config.token_page}`)).data;

    if (response.page) {
      setMetaTags(response.page.metaTitle, response.page.metaDescription, response.page.metaKeywords);
      setLoadedColors({
        primary: response.page.colorPrimary, secondary: response.page.colorSecondary, tertiary: response.page.colorTertiary, quaternary: response.page.colorQuaternary,
        border: response.page.colorBorder, background: response.page.colorBackground, form: response.page.colorForm, bgform: response.page.colorbgform, fontform: response.page.colorFontForm, btnform: response.page.colorbtnform, btnfont: response.page.colorBtnfont, networks: response.page.colorNetworks, description: response.page.colorDescription
      });
      let tel = response.page.whatsapp;
      setTelefone(tel);
      setEndereco(response.page.endereco);
      setContent({
        page: response.page,
        form_subjects: response.form_subjects.map((item: FormSubjects) => {
          return { label: item.name, value: item.id, setor:  item.id_setor}
        }),
        links: response.links,
        social_networks: response.social_networks,
        setores: response.setores
      });
      setLinksCount(response.links.length);
      setButtonName(response.page.buttonName);

      if (response.page.image_background) {
        setHasBg(true)
      }

      if (response.page.flagWhatsapp === true || response.page.flagEndereco === true || response.page.flagSocials) {
        setHasFooter(true);
      }

      if (response.page.flagMapa === true) {
        setHasMap(true);
        verifyMap(response.links.length);
      }

      if (response.page.googleAnalytics) {
        ReactGA.initialize([
          {
            trackingId: response.page.googleAnalytics
          }
        ]);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadContents();

    if (widthScreen <= 575 || (widthScreen >= 1280 && widthScreen <= 1600)) {
      seTtypeImage('small');
    }
  }, []);

  useEffect(() => {
    if (widthScreen > 575) {
      if (Object.keys(content).length !== 0) {
        const flagTel = content.page.flagWhatsapp;
        const flagEnd = content.page.flagEndereco;
        const tel = content.page.whatsapp;
        const address = content.page.endereco;
        const mapa = content.page.mapa;
        let info = '';

        // Possivel bug por aqui...
        if (flagTel === true && flagEnd === true) {
          info = address + ' - ' + tel;
        } else if (flagTel === false && flagEnd === true) {
          info = address;
        } else if (flagTel === true && flagEnd === false) {
          info = tel;
        }

        setFooterContent(info);
      }
    }
  }, [content]);

  useEffect(() => {
    if (!isLoading && hasFooter) {
      let element = document.getElementById('footer');

      if (element) {
        setHeightFooter(element.clientHeight);
      }
    }
  }, [isLoading, hasFooter]);

  const checkBackground = () => {
    if (content.page.image_background === null && content.page.colorBackground === '') {
      return defaultBg;
    } else {
      if (content.page.image_background) {
        return getImages(content.page.image_background.path)
      } else {
        return ''
      }
    }
  }

  const verifyMap = (contagemLinks: number) => {
    if (contagemLinks > 3) {
      setShowMapa(false);
    } else if (contagemLinks <= 3) {
      setShowMapa(true);
    }
  }

  return (
    <ThemeProvider theme={{
      ...theme,
      colors: {
        ...theme.colors,
        primary: loadedColors.primary,
        secondary: loadedColors.secondary,
        tertiary: loadedColors.tertiary,
        quaternary: loadedColors.quaternary,
        form: loadedColors.form,
        bgform: loadedColors.bgform,
        fontform: loadedColors.fontform,
        btnform: loadedColors.btnform,
        btnfont: loadedColors.btnfont,
        networks: loadedColors.networks,
        description: loadedColors.description,
        border: loadedColors.border,
        background: loadedColors.background
      }
    }}>
      {showFormModal && <FormModal subjects={content.form_subjects} buttonName={buttonName}  closeModal={() => setShowFormModal(false)} />}
      <GlobalStyles />
      {
        isLoading ? (
          <Loading >
            <VscLoading />
            <h2>Carregando...</h2>
          </Loading>
        ) : (
          <Container backgroundUrl={checkBackground()}>
            <Filter flagBackgroundFilter={content.page.flagBackgroundFilter} hasBg={hasBg} heightFooter={heightFooter}>
              <div className="scroller">
                {(content.page.image_logo) && <Logo logoUrl={getImages(content.page.image_logo.path)} type={typeImage as any} flagProfileBorder={content.page.flagProfileBorder} />}
                <Infos title={content.page.title} description={content.page.description} />
                <div className='content-links-map'>
                  <Links values={content.links} buttonName={content.page.buttonName} showModal={setShowFormModal} flagForm={content.page.flagForm} />
                  {(content.page.flagMapa && showMapa) && <Mapa src={content.page.mapa} />}
                </div>
              </div>
            </Filter>
            {(content.page.flagMapa && linksCount > 3) && <WrapMapa showMap={handleValueFromChild} hasFooter={hasFooter} />}
            {content.page.flagChat && <WrapChat phone={content.page.whatsapp} setores={content.setores} flagWhatsapp={content.page.flagWhatsapp} logoUrl={(content.page.image_logo) && getImages(content.page.image_logo.path)} flagProfileBorder={content.page.flagProfileBorder} hasFooter={hasFooter} socials={content.social_networks} />}
            {hasFooter &&
              <Footer id="footer" hasFooter={hasFooter}>
                <SocialsAndForm flagSocials={content.page.flagSocials} flagForm={content.page.flagForm}>
                  <div className="tel-address">
                    {widthScreen > 575 && <span className="endereco-corner">{footerContent}</span>}
                    {widthScreen <= 575 && (
                      <>
                        {content.page.flagEndereco && <span className="endereco-corner">{endereco}</span>}
                        {content.page.flagWhatsapp && <span className="whatsapp-corner">{telefone}</span>}
                      </>
                    )}
                  </div>
                  <div className="social-form">
                    {content.page.flagSocials && <Socials values={content.social_networks} />}
                  </div>
                </SocialsAndForm>
              </Footer>
            }
          </Container>
        )
      }
    </ThemeProvider >
  );
}

export default App;