import React, { useState, useEffect } from 'react';
import { FiMapPin } from 'react-icons/fi';
import { RiSubtractFill } from 'react-icons/ri';
import Socket from '../../services/Socket';
import { sessionGet } from '../../session';
import Chat from '../Chat';
import { SocialNetworks } from '../../components/Socials';

import { Container } from './styles';

interface WrapMapaProps {
  showMap: any,
  hasFooter: boolean,
};

const WrapMapa: React.FC<WrapMapaProps> = ({ showMap, hasFooter }) => {
  const [mapOpened, setMapOpened] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [count, setCount] = useState(0);

  const handleClick = () => {
    showMap(!mapOpened);
  }

  useEffect(() => {
    window.onresize = () => {
      setWidth(window.innerWidth);
    };
  }, [width])

  return (
    <>
      <Container width={width} mapOpened={mapOpened} hasFooter={hasFooter} onClick={() => { handleClick(); setCount(0); setMapOpened(!mapOpened) }}>
        {!mapOpened && <FiMapPin />}
        {mapOpened && <RiSubtractFill />}
        {(width > 950) ? !mapOpened && <span>Localização</span> : ''}
        {count > 0 ? <span className="notify-icon">{count}</span> : ''}
      </Container>

      {mapOpened && (
        // abre chat (carrega component)
        <></>
      )}
    </>
  );
}

export default WrapMapa;