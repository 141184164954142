import React, { useState, useEffect, useCallback } from 'react';
import GoogleAnalytics from '../../GoogleAnalytics';
import Select, { SelectOptions } from '../../Form/Select';
import Api from '../../../services/Api';
import Config from '../../../config';
import Logo from '../../Logo';
import Input from '../../Form/Input';
import Button from '../../Form/Button';
import Socket from '../../../services/Socket';
import { sessionSet } from '../../../session';
import { MdError } from 'react-icons/md';

import { Error, SelectHTML, Container } from './styles';

interface Setores {
  name: string,
  id: number
}

export interface StepProps {
  setStep: (value: any) => void,
  setAtendimento: (value: any) => void,
  logoUrl: string,
  listSetores: any,
  flagProfileBorder: boolean
}

const Cadastro: React.FC<StepProps> = ({ setStep, setAtendimento, logoUrl, listSetores, flagProfileBorder }) => {
  const [setores, setSetores] = useState<SelectOptions[]>([]);
  const [setor, setSetor] = useState<SelectOptions>({} as SelectOptions);
  const [statusFila, setStatusFila] = useState('next');
  const [hasSetor, setHasSetor] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneMask, setPhoneMask] = useState('(##) #####-####');
  const [option, setOption] = useState(0);
  const [msgError, setMsgError] = useState(false);
  const [mailMsg, setMailMsg] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [msgName, setMsgName] = useState(false);

  useEffect(() => {
    setSetores(listSetores.map((setor: Setores) => {
      return {
        label: setor.name,
        value: setor.id
      }
    }))
  }, []);

  const eventTrack = (category: string, action: string, label: string) => {
    GoogleAnalytics(category, action, label);
  }

  const handleUserRegister = async (e: React.FormEvent) => {
    e.preventDefault();

    if (name === '') {
      setMsgName(true);
      setTimeout(function () {
        setMsgName(false);
      }, 7000)
      return;
    }


    if (email === '') {
      setMsgError(true);
      setMailMsg('Insira um endereço de e-mail válido.');
      setTimeout(function () {
        setMsgError(false);
      }, 7000)
      return;
    }

    if (phone === '') {
      setPhoneError(true);
      setTimeout(() => {
        setPhoneError(false);
      }, 7000)
      return
    }

    if (option === 0) {
      setHasSetor(false);
      setTimeout(() => {
        setHasSetor(true);
      }, 7000)
      return
    }

    let endpoint = "";

    if (window.location.href.includes('merx.app.br') || window.location.href.includes('localhost')) {
      if (window.location.pathname.lastIndexOf("/")) {
        endpoint = window.location.pathname.substring(
          window.location.pathname.indexOf("/") + 1,
          window.location.pathname.lastIndexOf("/")
        );
      } else {
        endpoint = window.location.pathname.replace(new RegExp("/", "g"), '');
      }
    } else {
      endpoint = window.location.hostname;
    }

    const response = (await Api.post('/chat-atendimento', {
      name,
      email,
      phone,
      id_setor: option,
      endpoint: endpoint
    })).data;

    if (response.type === 'success') {
      eventTrack('Chat externo', 'Mensagem enviada pelo chat externo com sucesso!', 'Sucesso!');
      sessionSet(response);
      setAtendimento(response);
      setStep('fila');
    } else if (response.type === 'error') {
      setMailMsg(response.msg);
      setMsgError(true);
      setTimeout(() => {
        setMsgError(false);
      }, 7000)
    }
  }

  // Função que verifica qual o número de telefone (ceular ou fixo) digitado e coloca a respetiva máscara 
  const checkPhoneMask = useCallback(() => {
    const numbers = phone.split('');

    if (numbers[5] === '9') {
      setPhoneMask('(##) #####-####');
    } else {
      setPhoneMask('(##) ####-####');
    }
  }, [phone]);

  useEffect(() => {
    if (phone !== '' && phone !== '(__) _____-____') {
      checkPhoneMask();
    }
  }, [phone, checkPhoneMask]);

  return (
    <>
      <Container>
        {(logoUrl) && <Logo logoUrl={logoUrl} type='small' flagProfileBorder={flagProfileBorder} />}
        <p>Preencha o formulário abaixo para entrar em contato com um de nossos atendentes:</p>
        <form onSubmit={handleUserRegister} className="cadastro">
          <Input value={name} onChange={e => setName(e.target.value)} type="text" placeholder="Nome completo" />
          {(msgName) && <Error className="error-txt"><MdError /><span>Informe o seu nome para prosseguir.</span></Error>}
          <Input value={email} onChange={e => setEmail(e.target.value)} type="text" placeholder="E-mail" />
          {(msgError) && <Error className="error-txt"><MdError /><span>{mailMsg}</span></Error>}
          <Input value={phone} onChange={e => setPhone(e.target.value)} type="text" placeholder="Telefone" hasMask={true} formatMask={phoneMask} mask="_" />
          {(phoneError) && <Error className="error-txt"><MdError /><span>Insira um número de telefone para prosseguir.</span></Error>}
          {/* <Select label="Selecione um setor" value={setor} values={setores} setValue={(item: SelectOptions) => { setSetor(item) }} /> */}
          <SelectHTML className="select" value={option} onChange={e => setOption(Number(e.target.value))} >
            <option value={0}>Selecione o setor</option>
            {setores.map(setor => (
              <option value={setor.value}>{setor.label}</option>
            ))}
          </SelectHTML>
          {!hasSetor && <Error className="error-txt"><MdError /><span>Selecione um setor para continuar.</span></Error>}
          <Button type="submit" label="Iniciar conversa" />
        </form>
      </Container>
    </>
  );
}

export default Cadastro;