import React, { useState } from 'react';
import GoogleAnalytics from '../GoogleAnalytics';
import { Container, FaleConosco, Item } from './styles';

export interface LinksPage {
  id: number,
  title: string,
  link: string,
  position: number,
  flag_icon: boolean,
  buttonName: string,
}

interface LinksProps {
  values: LinksPage[],
  showModal: (value: boolean) => void;
  flagForm: boolean;
  buttonName:string;
}

const Links: React.FC<LinksProps> = ({ values, showModal, flagForm, buttonName }) => {

  const [iconLink, setIconLink] = useState(true);

  const icons = {
    amazon: <img src='https://img.merx.app.br/images/icones-minisite/amazon.webp' alt="logo Amazon" loading="lazy" />,
    americanas: <img src='https://img.merx.app.br/images/icones-minisite/americanas.webp' alt="logo Americanas" loading="lazy" />,
    apple: <img src='https://img.merx.app.br/images/icones-minisite/apple.webp' alt="logo Americanas" loading="lazy" />,
    deezer: <img src='https://img.merx.app.br/images/icones-minisite/deezer.webp' alt="logo Americanas" loading="lazy" />,
    facebook: <img src='https://img.merx.app.br/images/icones-minisite/facebook.webp' alt="logo Facebook" loading="lazy" />,
    g1: <img src='https://img.merx.app.br/images/icones-minisite/g1.webp' alt="logo G1" loading="lazy" />,
    instagram: <img src='https://img.merx.app.br/images/icones-minisite/instagram.webp' alt="logo Instagram" loading="lazy" />,
    magazineluiza: <img src='https://img.merx.app.br/images/icones-minisite/magazineluiza.webp' alt="logo Magalu" loading="lazy" />,
    mercadolivre: <img src='https://img.merx.app.br/images/icones-minisite/mercadolivre.webp' alt="logo Mercado Livre" loading="lazy" />,
    r7: <img src='https://img.merx.app.br/images/icones-minisite/r7.webp' alt="logo R7" loading="lazy" />,
    shein: <img src='https://img.merx.app.br/images/icones-minisite/shein.webp' alt="logo Shein" loading="lazy" />,
    shopee: <img src='https://img.merx.app.br/images/icones-minisite/shopee.webp' alt="logo Shopee" loading="lazy" />,
    spotify: <img src='https://img.merx.app.br/images/icones-minisite/spotify.webp' alt="logo Spotify" loading="lazy" />,
    submarino: <img src='https://img.merx.app.br/images/icones-minisite/submarino.webp' alt="logo Submarino" loading="lazy" />,
    tiktok: <img src='https://img.merx.app.br/images/icones-minisite/tiktok.webp' alt="logo Tiktok" loading="lazy" />,
    twitch: <img src='https://img.merx.app.br/images/icones-minisite/twitch.webp' alt="logo Twitch" loading="lazy" />,
    twitter: <img src='https://img.merx.app.br/images/icones-minisite/twitter.webp' alt="logo Twitter" loading="lazy" />,
    youtu: <img src='https://img.merx.app.br/images/icones-minisite/youtube.webp' alt="logo Youtube" loading="lazy" />
  }

  const linksComIcones = ["amazon", "americanas", "apple", "deezer", "facebook", "g1", "instagram", "magazineluiza", "mercadolivre", "r7", "shein", "shopee", "spotify", "submarino", "tiktok", "twitter", "twitch", "youtu"]

  const eventTrack = (category: string, action: string, label: string) => {
    GoogleAnalytics(category, action, label);
  }

  const iconsName = (link: string) => {
    let closestMatchIndex = -1;
    let closestMatchLength = 0;

    linksComIcones.forEach((option, index) => {
      if (link.indexOf(option) !== -1 && option.length > closestMatchLength) {
        closestMatchIndex = index;
        closestMatchLength = option.length;
      }
    });
    return linksComIcones[closestMatchIndex]
  }

  return (
    <Container>
      {values.map(item =>
        <li className='lista-links' onClick={eventTrack.bind(this, "Links", "Acesso ao link: " + item.title, "Link acessado através do mini site.")}>
          {linksComIcones.some(value => item.link.includes(value)) &&
            item.flag_icon &&
            (icons as Record<string, JSX.Element>)[iconsName(item.link)]
          }
          <Item target="_blank" href={item.link.includes('http') ? item.link : `//${item.link}`} >
            <div className={`desc-item ${iconsName(item.link) ? 'icon' : ''}`} key={item.id}>
              <span className="font-link">{item.title}</span>
            </div>
          </Item>
        </li>
      )
      }
      {flagForm &&
        <li>
          <FaleConosco className="contato" onClick={() => showModal(true)}>
            <div className="desc-item">
              <span className="font-contato">{buttonName}</span>
            </div>
          </FaleConosco>
        </li>
      }
    </Container>
  );
}

export default Links;